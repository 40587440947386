@use '../../Assets/css/var';

.loginMian {
    padding-top: 120px;
    padding-bottom: 30px;
}

.accountPreviewMain {
    padding-top: 0px;
    padding-bottom: 0px;

    .tablemainnet {
        .col-md-6 {
            margin-bottom: 30px;
        }

        .mainnetInr {
            padding: 30px;
            border-radius: 5px;
            box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, .2);
            height: 100%;
            border: 1px solid rgba(255, 255, 255, 0.239);

            &.setminheight {
                min-height: auto;
            }

            h3 {
                font-weight: 400;
                font-size: 24px;
                margin-bottom: 15px;
            }

            .autoLogin {
                justify-content: space-between;
                align-items: center;
                margin: 25px 0px 0px;
            }
        }

        .dashpgMbr {
            .mainnetInr {
                padding: 15px;

                h3 {
                    font-size: 16px;
                    min-height: 38px;
                }
            }
        }

        &.rightBox {
            .mainnetInr {
                padding: 0px;
                border-radius: 5px;
                box-shadow: none;
                height: 100%;
                border: none;

                &.reglogBox {
                    padding: 30px;
                    border-radius: 5px;
                    box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, .2);
                    height: 100%;
                    border: 1px solid #1c1c1c;
                    margin-bottom: 30px;
                    min-height: auto;
                }

                &.loginwithv1v2Main {
                    min-height: auto;
                }
            }
        }

        .tableInr {
            padding: 0px;
            height: 100%;
            margin-bottom: 40px;
            margin-top: 20px;
            position: relative;

            h3 {
                font-weight: 400;
                font-size: 24px;
                margin-bottom: 15px;

                span {
                    color: var.$colour-theme;
                }
            }

            .table-responsive {
                overflow-x: auto;
                max-height: 372px;
                padding-right: 10px;

                &.dashboardPg {
                    max-height: 372px;
                    font-size: 14px;

                    table {
                        tr {
                            td {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }

            table {
                margin-bottom: 0px;
                min-width: 510px;
                background-color: transparent;

                tbody>tr:nth-of-type(odd)>* {
                    background-color: transparent;
                }

                tr {
                    color: #ffffff;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 6px;
                    margin-bottom: 15px;
                    background-color: rgba(255, 255, 255, 0);
                    font-weight: 700;
                    border: 1px solid rgba(255, 255, 255, 0.25);
                    box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, .2);

                    th {
                        border-color: #0b0b0c;
                        color: var.$colour-theme;
                        font-weight: 400;
                        vertical-align: middle;
                        padding: 0px;
                        background-color: transparent;
                    }

                    td {
                        border: none;
                        color: #ffffff;
                        font-weight: 400;
                        vertical-align: middle;
                        background-color: transparent;

                        img {
                            height: 28px;
                            width: auto;
                            display: inline-block;
                            margin-right: 20px;
                        }

                        svg {
                            height: 24px;
                            width: auto;
                            margin-right: 10px;
                        }

                        a {
                            display: inline-block;
                            text-align: center;
                            font-size: 18px;
                            text-decoration: none;
                            border: none;
                            padding: 5px 10px 3px;
                            line-height: 1;
                            background-color: #ffffff;
                            border-radius: 3px;
                            color: #000000;
                            font-weight: 600;
                        }

                        &.linkshr.text-end {
                            padding-right: 20px;

                            a {
                                background-color: transparent;
                            }
                        }

                        &.txtinf {
                            p {
                                margin: 0px;
                                display: inline-block;
                                color: var.$colour-theme;
                            }

                            span {
                                display: inline-block;
                                margin: 0 10px;
                                color: #9d9d9d;
                            }
                        }
                    }

                    &:nth-last-child(1) {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

.accountPreviewbtm {
    margin-bottom: 0px;
}

.cardbox {
    padding: 0px 50px;

    .col-6 {
        padding: 0px;

        &:nth-child(1) {
            .inrBox {
                transform: rotate(-22deg) translateY(18px);
            }
        }

        &:nth-child(2) {
            .inrBox {
                transform: rotate(-8deg) translateY(-60px) translateX(10px);
            }
        }

        &:nth-child(3) {
            .inrBox {
                transform: rotate(8deg) translateY(-60px) translateX(-10px);
            }
        }

        &:nth-child(4) {
            .inrBox {
                transform: rotate(22deg) translateY(18px);
            }
        }
    }
}

.inrBox {
    border: 1px solid #957782;
    padding: 20px;
    text-align: center;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    height: 100%;
    gap: 20px;
    overflow: hidden;
    position: relative;

    h3 {
        font-size: 20px;
        font-weight: 500;
    }

    p {
        font-size: 18px;
        font-weight: 400;
        margin: 0px;
    }

    .iconbgbox {
        width: 240px;
        height: 240px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0px auto;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('../../Assets/images/iconsbg.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            /* Ensure it's properly sized */
            height: 100%;
            z-index: -1;
            opacity: 0.45;
            animation: rotatetranslate 40s linear infinite;
            transform-origin: center center;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0px;
            height: 110px;
            width: auto;
            max-height: 110px;
            max-width: 110px;
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
            padding: 7px;
            border-radius: 100px;
            z-index: 3;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 150%;
        background-image: url('../../Assets/images/banner-bottom.webp');
        background-position: bottom center;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .15;
        animation: hueanimrotatelogo 7s linear infinite;
        transform-origin: center;
    }

    &:hover {
        .iconbgbox {
            &::before {
                animation: rotatetranslate 0s linear infinite;
                transform-origin: center;
                opacity: 0.65;
            }
        }
    }
}