@use '../../Assets/css/var';

.treeAeromatix {
    .treebox {
        padding: 10px 0px 0px;
        text-align: left;
        background-color: transparent;
        border-radius: 3px;
        color: #ffffff;
        height: 100%;
        cursor: pointer;
        position: relative;
        background: transparent;
        border-top: 1px ridge #db9999;
        border-left: 1px groove #bb7a7a;
        border-right: 1px ridge #db9999;
        border-bottom: 1px groove #bb7a7a;
        border-radius: 15px;

        &>.row {
            margin: 0px;
        }

        .nftplace {
            padding: 0px;
        }

        .headeline {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                width: auto;
                height: 20px;
                margin-right: 5px;
                position: relative;
                top: -2px;
            }
        }

        .downSticky {
            position: sticky;
            top: 100%;
        }

        .upgradeBtn {
            text-align: center;
            position: absolute;
            z-index: 2;
            top: 0px;
            right: 0px;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px auto;
                padding: 10px 10px 10px 20px;
                border-radius: 0px 0px 0px 50px;

                svg {
                    position: relative;
                    z-index: 2;
                    fill: #000;
                    border: 1px solid #000;
                    border-radius: 50px;
                    margin-right: 5px;
                    width: 25px;
                    height: 25px;
                }

                b {
                    position: relative;
                    z-index: 2;
                    color: #000000;
                    font-weight: 500;
                }
            }
        }

        .footerBox {
            justify-content: space-between;
            margin: 0px;
        }

        .footerlineone {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0px;

            svg {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .footerline {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0px;
            position: absolute;
            bottom: 20px;
            left: 20px;
            z-index: 9;

            svg {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .itemsmain {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            .col6 {
                flex: 0 0 50%;
                max-width: 50%;
                display: flex;
                flex-wrap: wrap;
            }

            .col12 {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;
            }

            span {
                display: block;
                border-radius: 0px;
                width: 30px;
                height: 30px;
                margin: 0 auto 0px;
                // background-color: var.$colour-theme;
                cursor: pointer;
                // transform: rotate(45deg);

                &.dark {
                    filter: grayscale(1);
                }
            }

            .col6 {
                .col6 {
                    span {
                        width: 20px;
                        height: 20px;
                        margin-bottom: 10px;
                    }

                    .col6 {
                        span {
                            width: 10px;
                            height: 10px;
                            margin-bottom: 10px;
                        }

                        .col6 {
                            span {
                                width: 6px;
                                height: 6px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }

        .imgaeboxNft {
            text-align: center;
            position: relative;

            h2 {
                font-size: 14px;
                margin: 0 0 20px;
                text-align: center;
            }

            img {
                height: 100%;
                width: 100%;
                border-radius: 0px;
                position: relative;
                object-fit: contain;
                border-radius: 0px 0px 15px 15px;
            }

            .lazy-load-image-background {
                background-image: url('../../Assets/images/loading.gif');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 50px 50px;
            }

            .imgBxnft {
                &>.lazy-load-image-background {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
        }

        span svg {
            width: 18px;
            height: auto;
            margin-left: 3px;
        }

        .sbdb {
            display: block;
            font-size: 14px;
            opacity: 0.7;
            margin-top: 5px;
            line-height: 1;
        }
    }
}

// .treeAeromatix.myNftBx .treebox .imgaeboxNft img {
//     height: 100%;
//     object-fit: contain;
//     position: relative;
//     width: 100%;
//     padding: 0px;
//     border-radius: 5px;
// }