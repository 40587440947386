@use '../../Assets/css/var';

.accountPreviewMain .tablemainnet .tableInr table tr th {
    border: none;
}

.accountPreviewMain .tablemainnet .tableInr table tbody {
    margin-top: 0px;
    // display: block;
}

.tabPools {
    margin-top: 30px;
    margin-bottom: 30px;

    .MuiTabs-scrollButtons {
        width: 5px;
    }

    .MuiTabs-flexContainer {
        // background-color: #fff;
        justify-content: center;
        max-width: fit-content;
        margin: 0 auto;
    }

    .MuiTabs-indicator {
        background-color: #ffffff;
    }
}

.tabPools {
    .am_btnx {
        margin: 0px 4px;

        button {
            color: #000 !important;
            font-weight: 500 !important;
            border-radius: 0px !important;
            font-size: 16px;
            line-height: 1;
            padding: 8px 10px;
            text-align: center;
            background: linear-gradient(145deg, #ff8e8e, #ffaeae, #dabe35) !important;
            box-shadow: none;
            outline: none;
            transition: all .3s ease-in-out;
            border: none;
            opacity: 1;
        }
    }
}

.progileDashBx {
    margin-bottom: 50px;
}

.poolhead {
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
}
.poolpage{
    padding: 100px 60px 10px;
}