@use '../../Assets/css/var';

.treeAeromatix {
    .treebox {
        .itemsmain {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            .col6 {
                flex: 0 0 50%;
                max-width: 50%;
                display: flex;
                flex-wrap: wrap;
            }

            .col12 {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;
            }

            span {
                display: block;
                border-radius: 0px;
                width: 30px;
                height: 30px;
                margin: 0 auto 0px;
                // background-color: var.$colour-theme;
                background-image: url(../../Assets/images/logofav.webp);
                background-repeat: no-repeat;
                background-size: contain;
                cursor: pointer;
                // transform: rotate(45deg);

                &.dark {
                    filter: grayscale(1);
                }
            }

            .col6 {
                .col6 {
                    span {
                        width: 20px;
                        height: 20px;
                        margin-bottom: 10px;
                    }

                    .col6 {
                        span {
                            width: 10px;
                            height: 10px;
                            margin-bottom: 10px;
                        }

                        .col6 {
                            span {
                                width: 6px;
                                height: 6px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }

    }
}