.imagebox {
  max-width: 160px;
  margin: 30px auto;
  border-radius: 50%;
  text-align: center;
}

.imagebox img {
  animation: move 3s linear infinite;
  transition: all 0.5s;
}

.titlebg {
  color: #2196f3;
  font-size: 25px;
}

.joinbtn {
  background-color: #03a9f4;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: bolder;
}

.MuiPaper-root {
  background-color: #000 !important;
  box-shadow: inset 0 0 19px #f0e5e5d4 !important;
  padding-inline: 7px;
  padding-block: 7px;
  max-width: 650px !important;
  border-radius: 14px !important;
  position: relative !important;
  background: #f00;
  margin: 15px !important;
}

.addbgneon .MuiPaper-root {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, #ffa6a6, #936c00);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(315deg, #ffa6a6, #936c00);
    filter: blur(30px);
  }
}

.MuiTabs-flexContainer {
  justify-content: center;
}

.boxcontent {
  background: #000;
  position: relative;
  z-index: 99;
  border-radius: 6px;
  overflow: auto;

  .MuiDialogContent-root {
    padding: 0px !important;
  }

  .iconwrap {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 9;
    cursor: pointer;
  }

  .imagepopup {
    img {
      width: 100%;
      max-height: 91vh;
    }
  }

  .css-ttwr4n {
    left: 160px;
  }

  .imagepopup .criss-img {
    height: 550px
  }
}

.InputContainer {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  border-radius: 18px;
  overflow: hidden;
  cursor: pointer;
  padding-left: 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.075);
  padding-inline: 5px;
}

.MuiButtonBase-root {
  color: #Fff !important;
  background: #2692e0 !important;
}

.MuiTabs-scroller {
  .MuiTabs-indicator {
    background-color: #fff;
  }

}

.MuiDialogContent-root {
  .MuiTabPanel-root {
    padding: 15px 0px;
  }

}

.input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9em;
  caret-color: rgb(255, 81, 0);
}

.labelforsearch {
  cursor: text;
  padding: 0px 12px;
}

.searchIcon {
  width: 13px;
}

.border {
  height: 40%;
  width: 1.3px;
  background-color: rgb(223, 223, 223);
}

.micIcon {
  width: 12px;
}

.micButton {
  /* padding: 0px 15px 0px 12px; */
  border: none;
  background-color: transparent;
  /* height: 40px; */
  cursor: pointer;
  transition-duration: 0.3s;
  max-width: 35px;
  border-radius: 50%;
  background: #2196f3;
  height: 35px;
  margin-left: 30px;
}

.css-ypiqx9-MuiDialogContent-root {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 26px !important;
  padding-top: 10px !important;
}

.micButton svg {
  max-width: 100%;
  height: auto;
  filter: invert(1);
}

.searchIcon path {
  fill: rgb(114, 114, 114);
}

.micIcon path {
  fill: rgb(255, 81, 0);
}

.micButton:hover {
  filter: brightness(0.8);
  transition-duration: .3s;
}

.popuptitle {
  font-size: 35px;
}

.iconcolor {
  color: #ffa6a6;
  // margin-bottom: 20px;
  width: 25px !important;
}

.textpopup {
  color: #fff;
  text-align: center;

  h1 {
    font-size: 22px;
  }

  ul {
    text-align: justify;
  }

  display: none;
}

@keyframes move {

  0% {
    transform: scale(0.7)
  }

  50% {
    transform: scale(1)
  }

  100% {
    transform: scale(0.7)
  }
}

@media (max-width:768px) {
  .imagebox {
    max-width: 160px;
    margin: 0 auto;

  }

  .popuptitle {
    font-size: 28px;
  }
}