@use '../../Assets/css/var';

.faqOuterSection {
    position: relative;
    color: #ffffff;

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 80%;
        background-image: url('../../Assets/images/banner-bottom.webp');
        background-position: bottom center;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: .15;
        animation: hueanimrotatelogo 7s linear infinite;
        transform-origin: center;
        transform: rotate(25deg);
        pointer-events: none;
    }

    .faqrow {
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 80px;
    }

    .accordion-button::after {
        filter: brightness(5) grayscale(1);
    }

    .accordion-item {
        background-color: transparent;
        border: none;
        padding: 12px 0px;
        border-radius: 5px;
        position: relative;

        &:before {
            content: "";
            opacity: .5;
            background-image: linear-gradient(90deg, hsla(22, 88%, 78%, 0), #f8b994 17%, #ff97d5 75%, rgba(255, 151, 213, 0));
            width: 100%;
            height: 1px;
            margin-left: auto;
            margin-right: auto;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        button.accordion-button {
            background-color: transparent !important;
            border: none !important;
            box-shadow: none !important;
            color: #ffffff !important;
            font-weight: 400 !important;
            font-size: 20px;

            span {
                margin-right: 32px;
            }

            b {
                font-weight: 600;
                line-height: 1;
                color: transparent;
                background-clip: text;
                background: linear-gradient(to right, #fecaca, #fca5a5, #fde047);
                -webkit-background-clip: text;
                font-family: 'mantraregularfnt';
            }
        }

        p {
            color: #9ca3af;
            font-size: 18px;
            line-height: 1.1;
        }

        &:nth-last-child(1) {
            &:before {
                display: none;
            }
        }
    }
}