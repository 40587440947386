@use '../../Assets/css/var';

.sidebarMenu {
    flex: 0 0 100%;
    max-width: 100% !important;
    margin-right: 0px;
    padding-top: 0px;
    position: relative;
    top: 0px;
    align-self: flex-start;
    margin-top: 95px !important;
    margin-bottom: 20px;

    ul {
        padding: 6px;
        margin: 0px;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, 0.2);
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: -10px;
            left: 10px;
            width: calc(100% - 20px);
            height: calc(100% - -20px);
            border: 1px solid rgba(255,255,255,.3);
            box-shadow: inset 0px 0px 35px 3px rgba(255,255,255,.2);
            padding: 6px;
            margin: 0px;
            list-style: none;
            background-color: rgba(0,0,0,0);
            border-radius: 6px;
            z-index: -1;
        }

        li {
            margin-bottom: 0px;
            margin: 0 20px;

            a {
                color: #ffffff;
                text-decoration: none;
                padding: 10px 0px;
                display: flex;
                align-items: center;

                span {
                    position: relative;
                    top: 2px;
                }

                svg {
                    margin-right: 5px;
                    transform: scale(0.8);
                    transform-origin: center;
                }

                &.active {
                    color: #ffa6a6;
                    font-weight: bold;

                    &+a {
                        color: #ffa6a6;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}