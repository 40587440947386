@use '../../Assets/css/var';

.sf_banner {
    min-height: auto;
    padding-top: 100px;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 80%;
        background-image: url('../../Assets/images/banner-bottom.webp');
        background-position: bottom center;
        z-index: -1;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.17;
        animation: hueanimrotatelogo 7s linear infinite;
        transform-origin: center;
    }

    h1 {
        font-size: 98px;
        line-height: 1;
        margin-bottom: 5px;
        color: transparent;
        background-clip: text;
        background: linear-gradient(to right, #fecaca, #fca5a5, #fde047);
        -webkit-background-clip: text;
    }

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
    }

    .ml-auto {
        margin-left: auto;
    }

    .mr-auto {
        margin-right: auto;
    }

    .box2 {
        margin-top: 20px;
    }

    .buttoncircle {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-image: url('../../Assets/images/buttonbg.webp');
            background-position: center;
            z-index: -1;
            background-repeat: no-repeat;
            background-size: contain;
            animation: hueanimrotate1 7s linear infinite;
            transform-origin: center;
        }

        button,
        a {
            padding: 0px;
            border: none;
            background-color: transparent;
            font-size: 20px;
            text-align: center;
            color: #ffffff;
            letter-spacing: 1px;
        }
    }
}

.circlebox {
    width: 100%;
    max-width: 650px;
    margin: 0px auto;

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 110%;
        height: 110%;
        background-image: linear-gradient(to bottom, transparent 30%, #150200 90%);
        z-index: 1;
    }

    img.circleimg {
        width: 100%;
        height: auto;
        animation: hueanimrotate 60s linear infinite;
        transform-origin: center;
        filter: brightness(1.3);
    }

    img.logopng {
        position: absolute;
        top: 58%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        max-height: 400px;
        animation: hueanimrotatelogo 7s linear infinite;
        transform-origin: center;
        z-index: 1;
    }
}