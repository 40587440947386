@use '../../Assets/css/var';

.treeAeromatix {
    .treeboxDetail {
        padding: 20px;
        border-radius: 5px;
        text-align: left;
        background-color: transparent;
        border: none;
        box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, .2);
        color: #ffffff;
        height: 100%;

        .headeline {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .downSticky {
            position: sticky;
            top: 100%;
        }

        .cartBox {
            text-align: center;
            margin: 30px auto 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;

            button {
                border: 1px solid #333333;
                outline: none;
                background-color: #333333;
                color: #ffffff;
                cursor: pointer;
                width: 50px;
                height: 50px;
                border-radius: 60px;
                display: flex;
                margin-bottom: 10px;
                box-shadow: inset 0px 0px 10px rgb(0, 0, 0);
                align-items: center;
                justify-content: center;
                margin-left: auto;
                margin-right: auto;
            }

            &>a {
                display: block;
                padding: 5px;
                line-height: 1.1;
            }
        }

        .footerBox {
            justify-content: space-between;
            margin: 0px;
        }

        .footerlineone {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0px;

            svg {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .footerline {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0px;

            svg {
                width: auto;
                height: 20px;
                margin-right: 5px;
            }
        }

        .itemsmain {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            .col6 {
                flex: 0 0 50%;
                max-width: 50%;
                display: flex;
                flex-wrap: wrap;
            }

            .col12 {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;
            }

            span {
                display: block;
                border-radius: 0px;
                width: 40px;
                height: 40px;
                margin: 0 auto 0px;
                background-color: var.$colour-theme;
                cursor: pointer;
                clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);

                &.dark {
                    filter: grayscale(1);
                }

                &.whiteColor {
                    background-color: #ffffff;
                }

                &.blueColor {
                    background-color: #7595ff;
                }

                &.yellowColor {
                    background-color: #fe97cd;
                }

                &.greenColor {
                    background-color: #00af00;
                }
            }

            .col6 {
                .col6 {
                    span {
                        width: 30px;
                        height: 30px;
                        margin-bottom: 10px;
                    }

                    .col6 {
                        span {
                            width: 20px;
                            height: 20px;
                            margin-bottom: 10px;
                        }

                        .col6 {
                            span {
                                width: 10px;
                                height: 10px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }

        .imgaeboxNft {
            text-align: center;

            h2 {
                font-size: 14px;
                margin: 0 0 20px;
                text-align: center;
            }

            img {
                width: auto;
                height: auto;
                max-height: 250px;
                border-radius: 3px;
            }
        }
    }

    .dropdownBxCycle {
        margin: 20px auto;
        max-width: 320px;

        .dropdown-toggle {
            color: #ffffff;
            font-size: 20px;
            background-color: #000000;

            &+.dropdown-menu {
                transition: none !important;
                inset: unset !important;
                top: calc(100% + -1px) !important;
                left: 10px !important;
                transform: none !important;
                width: calc(100% - 20px) !important;
            }
        }
    }
}

.treeBoxSingleView {
    .sidepnl {
        background-color: #ffa6a6;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        position: relative;
        cursor: pointer;
        flex: 0 0 80px;
        max-width: 80px;
        padding: 8px;
        color: #000000;

        &.disableBx {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .treeBoxSingleInr {
        flex: 0 0 calc(100% - 160px);
        max-width: calc(100% - 160px);
    }

    .treeboxDetail {
        .topheadbx {
            margin-bottom: 40px !important;
            border-bottom: 1px solid #1e1e1e;
            padding-bottom: 20px;
        }

        .downSticky {
            margin-top: 40px !important;
            border-top: 1px solid #1e1e1e;
            padding-top: 20px;
        }

        .itemsmain {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;

            .col6 {
                flex: 0 0 50%;
                max-width: 50%;
                display: flex;
                flex-wrap: wrap;
            }

            .col12 {
                flex: 0 0 100%;
                max-width: 100%;
                display: flex;
                flex-wrap: wrap;
            }

            span {
                width: 100px;
                height: 100px;
                cursor: pointer;
                clip-path: none;
                color: #000;
                font-weight: 500;
                font-size: 34px;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                opacity: 0.9;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: inherit;
                    z-index: -1;
                    transform: rotate(45deg);

                }

                img {
                    width: 100%;
                    padding: 20px;
                }
            }

            .col6 {
                .col6 {
                    span {
                        width: 85px;
                        height: 85px;
                        font-size: 30px;
                        margin-bottom: 25px;
                    }

                    .col6 {
                        span {
                            width: 65px;
                            height: 65px;
                            margin-bottom: 25px;
                            font-size: 26px;
                        }

                        .col6 {
                            span {
                                width: 32px;
                                height: 32px;
                                margin-bottom: 0px;
                                font-size: 16px;

                                img {
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .imgaeboxNft {
            text-align: center;

            h2 {
                font-size: 14px;
                margin: 0 0 20px;
                text-align: center;
            }

            img {
                width: auto;
                height: auto;
                max-height: 250px;
                border-radius: 3px;
            }
        }
    }
}

.treeColorGrp {
    margin: 50px 0px 20px;

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        text-align: center;

        li {
            display: inline-block;
            margin-right: 30px;
            margin-bottom: 15px;

            span {
                display: flex;
                align-items: center;

                svg {
                    height: 20px;
                    width: auto;
                    margin-right: 8px;
                }

                &::before {
                    content: "";
                    border-radius: 0px;
                    background-color: #ffffff;
                    margin-right: 8px;
                    display: block;
                    width: 20px;
                    height: 20px;
                    flex: 0 0 20px;
                    max-width: 20px;
                    transform: rotate(45deg);
                }

                &.remDot {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.treeColorGrp {
    ul {
        li {
            .whiteColor {
                &::before {
                    background-color: #ffffff;
                }
            }

            .blueColor {
                &::before {
                    background-color: #7595ff;
                }
            }

            .yellowColor {
                &::before {
                    background-color: #fe97cd;
                }
            }

            .greenColor {
                &::before {
                    background-color: #00af00;
                }
            }
        }
    }
}

.treeAeromatix .treeboxDetail .itemsmain span.greyColor {
    background-color: #4b4b4b;
}

.dateFormSection {
    position: relative;
    z-index: 999;
    margin-bottom: 10px;

    p {
        margin-bottom: 5px;
        text-align: left;
        font-weight: 400;
    }

    input {
        width: 100%;
        display: block;
        padding: 10px;
        line-height: 1;
        background-color: #ffffff;
        color: #000000;
        font-weight: 400;
        border: none;
        border-radius: 4px;
        font-size: 18px;
        outline: none;
        box-shadow: none;
    }

    .dateFormInr {
        background-color: #111111;
        padding: 20px 20px 30px;
        border: 1px solid #2c2c2c;
        margin: 0;
        border-radius: 5px;
        position: relative;
        padding-right: 120px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid rgba(255, 255, 255, 0.25);
        box-shadow: inset 0px 0px 35px 3px rgba(255, 255, 255, .2);
    }

    .css-b62m3t-container {
        width: 100%;
        display: block;
        line-height: 1;
        background-color: #ffffff;
        color: #000000;
        font-weight: 400;
        border: none;
        border-radius: 4px;
        font-size: 18px;
    }

    .css-13cymwt-control,
    .css-t3ipsp-control {
        padding-top: 3px;
        padding-bottom: 2px;
        outline: none;
        box-shadow: none;
        border: none;
    }

    .inrBoxSubmitBtn {
        display: inline-block;
        padding: 0;
        margin: 0;
        position: absolute;
        right: 30px;
        bottom: 31px;
        width: 80px;
        text-align: center;

        button {
            display: block;
            background-color: #ffa6a6;
            width: 100%;
            font-size: 18px;
            color: #000000;
            font-weight: bold;
            line-height: 1;
            border: none;
            padding: 12px 6px;
            border-radius: 4px;
            outline: none;
            box-shadow: none;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.profilebox {
    padding: 0px;
    border-radius: 0;
    text-align: center;
    background-color: transparent;
    border: none;

    .imgboxprof {
        position: relative;
        max-width: 100%;
        width: auto;
        padding: 0px;
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @keyframes circleAnimRotatePro {
            0% {
                transform: translate(-50%, -50%) rotate(0deg);
            }

            100% {
                transform: translate(-50%, -50%) rotate(360deg);
            }
        }
    }

    img {
        max-width: 100%;
        width: 100px;
        height: auto;
        position: relative;
        left: 0px;
        top: 0px;
    }

    h3 {
        font-size: 18px;
        margin-bottom: 5px;
        color: #ffffff;
    }

    h4 {
        font-size: 18px;
        margin-bottom: 15px;
        font-family: mantraregularfnt;
        font-weight: 400;
        color: #ffffff;
    }

    h2 {
        font-size: 28px;
        margin: 8px auto;
        color: #ffa6a6;
    }

    .editProfileBtn {
        display: inline-block;
        margin-bottom: 10px;
        color: #01c4ff;
        font-size: 16px;
        font-weight: 400;
    }

    .profileLinks {
        padding: 0px;
        margin: 0px 0px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;

        li {
            margin: 0px 6px 8px;

            a {
                display: block;
                outline: none;
                border: none;
                box-shadow: none;
            }

            svg {
                filter: invert(1);
                width: auto !important;
                height: 22px !important;
                margin: 0px !important;
            }
        }
    }

    p {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px dashed #afafaf;
        padding: 10px 10px;
        max-width: 220px;
        margin: 15px auto 0px;
        border-radius: 5px;
        letter-spacing: 2px;
    }

    svg {
        width: 28px !important;
        height: 28px !important;
        margin-left: 15px;
        cursor: pointer;
        text-align: center;
        padding: 0px;
        color: #ffa6a6;
    }
}